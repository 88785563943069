var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm", staticClass: "q-mb-sm" },
        [
          _c(
            "c-card",
            { staticClass: "cardClassDetailForm", attrs: { title: "상세" } },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              isSubmit: _vm.isSave,
                              url: _vm.saveUrl,
                              param: _vm.year,
                              mappingType: _vm.mappingType,
                              label: "저장",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveYear,
                              btnCallback: _vm.saveYearCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-6 col-md-6 col-lg-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "실시계획서명",
                        name: "planName",
                      },
                      model: {
                        value: _vm.year.planName,
                        callback: function ($$v) {
                          _vm.$set(_vm.year, "planName", $$v)
                        },
                        expression: "year.planName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-2 col-md-2 col-lg-2" },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        type: "year",
                        label: "년도",
                        name: "planYear",
                      },
                      model: {
                        value: _vm.year.planYear,
                        callback: function ($$v) {
                          _vm.$set(_vm.year, "planYear", $$v)
                        },
                        expression: "year.planYear",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-2 col-md-2 col-lg-2" },
                  [
                    _c("c-dept", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "주관부서",
                        name: "deptCd",
                      },
                      model: {
                        value: _vm.year.deptCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.year, "deptCd", $$v)
                        },
                        expression: "year.deptCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-2 col-md-2 col-lg-2" },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.year.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.year, "plantCd", $$v)
                        },
                        expression: "year.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 8,
                        label: "심사범위",
                        name: "auditScope",
                      },
                      model: {
                        value: _vm.year.auditScope,
                        callback: function ($$v) {
                          _vm.$set(_vm.year, "auditScope", $$v)
                        },
                        expression: "year.auditScope",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9" },
          [
            _c(
              "c-table",
              {
                ref: "plantable",
                attrs: {
                  title: "실시계획 목록",
                  columns: _vm.gridColumns,
                  data: _vm.grid.data,
                  gridHeight: _vm.gridHeight,
                  editable: _vm.editable && !_vm.disabled,
                  hideBottom: true,
                  isExcelDown: false,
                  filtering: false,
                  isFullScreen: false,
                  columnSetting: false,
                  selection: "multiple",
                  rowKey: "deptCd",
                  checkDisableColumn: "deptFlag",
                },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        props.rowIndex !== _vm.grid.data.length - 1
                          ? _c("q-checkbox", {
                              attrs: {
                                editable: _vm.editable && !_vm.disabled,
                                color: "orange-7",
                                "true-value": "Y",
                                "false-value": "N",
                                dense: "",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.datachange(props, col, "c")
                                },
                              },
                              model: {
                                value: props.row[col.name],
                                callback: function ($$v) {
                                  _vm.$set(props.row, col.name, $$v)
                                },
                                expression: "props.row[col.name]",
                              },
                            })
                          : _c("c-textarea-column", {
                              staticClass: "text-left",
                              attrs: {
                                editable: _vm.editable && !_vm.disabled,
                                col: col,
                                props: props,
                              },
                              on: {
                                datachange: function ($event) {
                                  return _vm.datachange(props, col, "t")
                                },
                              },
                            }),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "제외", icon: "remove" },
                              on: { btnClicked: _vm.deleteTarget },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "추가", icon: "add" },
                              on: { btnClicked: _vm.addTarget },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" },
          [
            _c(
              "c-table",
              {
                ref: "teamtable",
                attrs: {
                  title: "심사팀 목록",
                  columns: _vm.gridTeam.columns,
                  data: _vm.year.auditTeams,
                  gridHeight: _vm.gridHeight,
                  editable: _vm.editable && !_vm.disabled,
                  hideBottom: true,
                  isExcelDown: false,
                  filtering: false,
                  isFullScreen: false,
                  columnSetting: false,
                  selection: "multiple",
                  rowKey: "userId",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "제외", icon: "remove" },
                              on: { btnClicked: _vm.deleteTeam },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: { label: "추가", icon: "add" },
                              on: { btnClicked: _vm.addTeam },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }